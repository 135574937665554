import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Fab,
  IconButton,
  makeStyles,
  TextField,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  withStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Page from "src/components/Page";
import PageTitle from "src/components/PageTitle";
import AlertDialog from "src/components/AlertDialog";
import api from "src/services/api";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 16,
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
  },
  searchBox: {
    marginRight: "210px",
    width: "326px",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(12),
    right: theme.spacing(12),
  },
  green: {
    color: "#009C6D",
  },
  red: {
    color: "#E6202A",
  },
  skeletonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 37,
    width: "100%",
    height: "100%",
    "& 	.MuiSkeleton-root": {
      maxWidth: "1226px",
      padding: 0,
    },
  },
}));

const Grid = () => {
  const classes = useStyles();
  const navigate = useNavigate();


  const me = useSelector((state) => state.auth.user);

  const [isLoaded, setIsLoaded] = useState(false);
  const [grid, setGrid] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    let val = e.target.value;
    setSearched(val);

    if (val !== "") {
      const filteredRows = grid.filter((row) => {
        return row.grp_nome.toLowerCase().includes(val.toLowerCase());
      });

      setRows(filteredRows);
    } else {
      setRows(grid);
    }
  };

  const handleClick = () => {
    navigate("/app/nova-grade");
  };

  const changeStatus = (id) => {
    api
      .delete(`/gradeprogramacao/status/${id}`)
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          openSuccessAlert();
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const viewGrid = (id) => {
    navigate(`/app/grades/${id}`);
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Grade excluida com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (!isLoaded) {
      api
        .get("/gradeprogramacao")
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setGrid(resp.data.result);
            setRows(resp.data.result);
            setIsLoaded(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoaded]);

  return (
    <Page className={classes.root} title="Grade de programação">
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <PageTitle title="GRADE DE PROGRAMAÇÃO" />
        <TextField
          className={classes.searchBox}
          label="O que deseja localizar?"
          margin="normal"
          name="busca"
          onChange={(e) => requestSearch(e)}
          type="text"
          value={searched}
          variant="outlined"
        />
      </Box>

      {isLoaded ? (
        <Container className={classes.container}>
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="Tabela de clientes"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>NOME</StyledTableCell>
                  <StyledTableCell>CATEGORIA</StyledTableCell>
                  <StyledTableCell>QTD MÍDIAS</StyledTableCell>
                  <StyledTableCell>DURAÇÃO</StyledTableCell>
                  <StyledTableCell>STATUS</StyledTableCell>
                  <StyledTableCell>AÇÕES</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {item.grp_nome}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.cat_nome}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.conteudos.length}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {item.duration}
                    </StyledTableCell>
                    <StyledTableCell
                      className={item.grp_status ? classes.green : classes.red}
                    >
                      {item.grp_status ? "Ativo" : "Desativado"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton onClick={() => changeStatus(item.grp_id)}>
                        {item.grp_status ? <CloseIcon /> : <CheckIcon />}
                      </IconButton>
                      <IconButton onClick={() => viewGrid(item.grp_id)}>
                        <VisibilityIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {me.usr_tipoUsuario !== "caixa" && me.usr_tipoUsuario !== "cliente" ?
            <Fab color="secondary" className={classes.fab} onClick={handleClick}>
              <AddIcon fontSize="large" style={{ color: "#FFFFFF" }} />
            </Fab>
            : null
          }
        </Container>
      ) : (
        <div className={classes.skeletonContainer}>
          <Skeleton height={50} width={"100%"} />
          <Skeleton
            width={"100%"}
            variant="rectangular"
            height={"calc(100% - 150px)"}
          />
        </div>
      )}

      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
    </Page>
  );
};

export default Grid;
