import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import Page from "src/components/Page";
import PageTabTripleTitle from "src/components/PageTabTripleTitle";
import AlertDialog from "src/components/AlertDialog";
import MediaCard from "src/components/MediaCard";
import EditModal from "src/components/EditModal";
import { debounce } from "src/services/debounce";
import api from "src/services/api";
import capitalizeFirstLetter from "src/services/capitalizeFirstLetter";
import MidiaDaysModal from "src/components/MidiaDaysModal";
import Loader from "src/components/Loader";
import ConfirmActionModal from "src/components/ConfirmActionModal";
import daysWeek from "src/utils/daysWeek";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    maxWidth: "100vw",
  },
  fullBox: {
    width: "760px",
  },
  field: {
    marginRight: "25px",
  },
  buttonBox: {
    marginTop: "60px",
    marginBottom: "60px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  headerButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    "& button": {
      width: 250,
      marginLeft: theme.spacing(3),
    },
  },
  green: {
    color: "#009C6D",
  },
  mediaCardList: {
    width: "100%",
    maxWidth: "1400px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: theme.spacing(10),
    gap: theme.spacing(2),
    color: "textPrimary",
  },
  select: {
    margin: theme.spacing(2),
    width: "340px",
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  fadeIn: {
    animation: "$myEffect 1s ease",
  },
  placeholder: {
    position: "absolute",
    background: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgrey' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
  },
}));

const EditPlayerView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const { id } = useParams();

  const timeoutRef = useRef(null);

  const [startLoader, setStartLoader] = useState(false);
  const [activeTab, setactiveTab] = useState("primary");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertButtonText, setAlertButtonText] = useState("");
  const [lottery, setLottery] = useState({});
  const [currentPlayer, setCurrentPlayer] = useState({
    cli_razaoSocial: "",
    conexao: "",
    lot_razaoSocial: "",
    pla_a1Botao: "",
    pla_a1Duracao: "",
    pla_a2Botao: "",
    pla_a2Duracao: "",
    pla_a3Duracao: "",
    pla_a3Botao: "",
    pla_bloqueado: "",
    pla_codigo: "",
    pla_id: "",
    pla_lir_id: "",
    pla_lot_id: "",
    pla_nome: "",
    pla_posicao: "retrato",
    pla_status: "",
    pla_ultimaSincronizacao: "",
    pla_usr_id: "",
  });
  const [currentUser, setCurrentUser] = useState({
    usr_email: "",
    usr_nome: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [categories, setCategories] = useState([]);
  const [fadeProps, setFadeProps] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState("");
  const [dragNDropStartIndex, setDragNDropStartIndex] = useState(null);

  const [selectedMedia, setSelectedMedia] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [grid, setGrid] = useState([]);

  const [daysToDisplay, setDaysToDisplay] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "0",
  ]);
  const [midiaDaysToSave, setMidiaDaysToSave] = useState([]);
  const [openMidiaDaysModal, setOpenMidiaDaysModal] = useState(false);
  const [videoDuration, setVideoDuration] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const today = moment().day();
  const todayName = daysWeek[today];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nome: currentPlayer.pla_nome === null ? "" : currentPlayer.pla_nome,
      posicao:
        currentPlayer.pla_posicao === null ? "" : currentPlayer.pla_posicao,
      a1botao:
        currentPlayer.pla_a1Botao === null ? "" : currentPlayer.pla_a1Botao,
      a1duracao:
        currentPlayer.pla_a1Duracao === null ? "00:00:15" : currentPlayer.pla_a1Duracao,
      a2botao:
        currentPlayer.pla_a2Botao === null ? "" : currentPlayer.pla_a2Botao,
      a2duracao:
        currentPlayer.pla_a2Duracao === null ? "00:00:15" : currentPlayer.pla_a2Duracao,
      a3botao:
        currentPlayer.pla_a3Botao === null ? "" : currentPlayer.pla_a3Botao,
      a3duracao:
        currentPlayer.pla_a3Duracao === null ? "00:00:15" : currentPlayer.pla_a3Duracao,
      codigo: currentPlayer.pla_codigo === null ? "" : currentPlayer.pla_codigo,
      email: currentUser.usr_email === null ? "" : currentUser.usr_email,
      senhaAtual: "",
      senha: "",
      confirmaSenha: "",
    },
  });

  const handleSave = () => {
    let values = formik.values;

    if (!values.nome) return openErrorAlert("Insira o nome do player.");
    if (!values.codigo) return openErrorAlert("Insira o codigo do player.");

    setStartLoader(true);

    let obj = {
      pla_nome: !values.nome ? null : values.nome,
      pla_posicao: !values.posicao ? null : values.posicao,
      pla_a1Botao: !values.a1botao ? null : values.a1botao,
      pla_a1Duracao: !values.a1duracao ? null : values.a1duracao,
      pla_a2Botao: !values.a2botao ? null : values.a2botao,
      pla_a2Duracao: !values.a2duracao ? null : values.a2duracao,
      pla_a3Botao: !values.a3botao ? null : values.a3botao,
      pla_a3Duracao: !values.a3duracao ? null : values.a3duracao,
      pla_codigo: !values.codigo ? null : values.codigo,
      pla_lot_id: !lottery.lot_id ? null : lottery.lot_id,
      pla_id: !currentPlayer.pla_id ? null : currentPlayer.pla_id,
    };

    api
      .put("/player", obj)
      .then((resp) => {
        setStartLoader(false);
        openSuccessAlert();
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const handleDaysToDisplay = (e) => {
    const dayToAdd = e.target.value;

    const dayAlreadyAdd = daysToDisplay.find((day) => day === dayToAdd);
    if (dayAlreadyAdd) {
      setDaysToDisplay(daysToDisplay.filter((day) => day !== dayToAdd));
    } else {
      setDaysToDisplay([...daysToDisplay, dayToAdd]);
    }
  };

  const handleSaveUser = () => {
    setStartLoader(true);
    let values = formik.values;

    let user = {
      usr_id: currentPlayer.pla_usr_id,
      usr_email: values.email,
      usr_nome: currentUser.usr_nome,
      usr_tipoUsuario: "player",
    };

    let passwords = {
      usr_id: currentPlayer.pla_usr_id,
      usr_senha: values.senha,
    };

    api
      .put("/user", user)
      .then((resp) => {
        if (
          values.senha !== "" &&
          values.confirmaSenha !== "" &&
          values.senha === values.confirmaSenha
        ) {
          api
            .put("/user/senha", passwords)
            .then((resp) => {
              setStartLoader(false);
              openSuccessAlert();
              formik.values.senha = "";
              formik.values.confirmaSenha = "";
            })
            .catch((err) => {
              setStartLoader(false);
              console.log(err);
              openErrorAlert(err.response.data.message);
            });
        } else {
          setStartLoader(false);
          openSuccessAlert();
        }
      })
      .catch((err) => {
        setStartLoader(false);
        console.log(err);
        openErrorAlert(err.response.data.message);
      });
  };

  const openErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openFetchErrorAlert = (message) => {
    setAlertText(message);
    setAlertType("error");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const openSuccessAlert = () => {
    setAlertText("Player editado com sucesso.");
    setAlertType("success");
    setAlertButtonText("Fechar");

    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const fetchPlayer = () => {
    api
      .get(`/player/${id}`)
      .then((resp) => {
        const { result } = resp.data;
        setCurrentPlayer(result);
        fetchLotteries(result);
        fetchCategories();

        if (result.pla_usr_id) {
          fetchUser(result.pla_usr_id);
        }
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err?.response?.data?.message);
      });
  };

  const handleVerifyBtnTime = (btnDurationId) => {
    const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    const time = formik.values[btnDurationId];
    const timeDto = { ...formik.values };

    if (
      timeRegex.test(time) && time !== "00:00:00"
    ) {
      const [hour, minutes, seconds] = time.split(":").map(Number);

      if (!(hour >= 0 && hour <= 23 && minutes >= 0 && minutes <= 59 && seconds >= 0 && seconds <= 59)) {
        timeDto[btnDurationId] = '00:00:15';

        formik.setValues(timeDto);
      }
    } else {
      timeDto[btnDurationId] = '00:00:15';

      formik.setValues(timeDto);
    }
  }

  const fetchLotteries = (player) => {
    api
      .get("/loterica")
      .then((resp) => {

        let lotteryArr = resp.data.result.filter(
          (ele) => ele.lot_id === player.pla_lot_id
        );
        if (lotteryArr.length > 0) {
          setLottery(lotteryArr[0]);
        }

        if (player.pla_lir_id) {
          fetchGrid(player.pla_lir_id);
        }
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      });
  };

  const fetchCategories = () => {
    api
      .get("/categoria/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setCategories(resp.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUser = (userId) => {
    api
      .get(`/user/${userId}`)
      .then((resp) => {
        const { result } = resp.data;
        setCurrentUser(result);
      })
      .catch((err) => {
        console.log(err);
        openFetchErrorAlert(err.response.data.message);
      });
  };

  const changeActiveTab = (tab) => {
    setactiveTab(tab);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragStart = (event, startIndex) => {
    setDragNDropStartIndex(startIndex);

    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    const mediaToRelocateId = event.target.id;

    if (!mediaToRelocateId) return console.log("ID Ñ ENCONTRADO.");

    const mediaToRelocate = selectedMedia.find((media) => {
      return (
        media.con_id == Number(mediaToRelocateId) ||
        media.mul_id == Number(mediaToRelocateId) ||
        media.not_id == Number(mediaToRelocateId) ||
        media.cli_id == Number(mediaToRelocateId) ||
        media.id == Number(mediaToRelocateId)
      );
    });

    const mediaToRelocateIndex = selectedMedia.indexOf(mediaToRelocate);

    const reordered = reorder(
      selectedMedia,
      dragNDropStartIndex,
      mediaToRelocateIndex
    );

    setDragNDropStartIndex(null);
    setSelectedMedia(reordered);

    event.dataTransfer.clearData();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      selectedMedia,
      result.source.index,
      result.destination.index
    );

    setSelectedMedia(items);
    setFadeProps(classes.fadeIn);
  };

  const handleCleanMedia = () => {
    setSelectedMedia([]);
    setConfirmModal(false);
    setStartLoader(true);

    if (currentPlayer.pla_lir_id !== "" && currentPlayer.pla_lir_id !== null) {
      let gridContent = {
        lir_id: currentPlayer.pla_lir_id,
        conteudos: [],
      };

      api
        .put("/listaReproducao", gridContent)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            setStartLoader(false);
            openSuccessAlert();
          }
        })
        .catch((err) => {
          console.log(err);
          setStartLoader(false);
          openErrorAlert(err.response.data.message);
        });
    } else {
      setStartLoader(false);
      openErrorAlert("Não foi possivel limpar a lista de reprodução.");
    }
  };

  const handleMediaGrid = () => {
    setType("media-grid");
    setOpenModal(true);
  };

  const handleUpload = () => {
    setType("upload");
    setOpenModal(true);
  };

  const handleUploadNews = () => {
    api
      .get("/noticia/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          resp.data.result.forEach((el) => {
            if (el.not_nome) {
              el.not_nome = capitalizeFirstLetter(el.not_nome);
            }

            if (el.not_categoria) {
              el.not_categoria = capitalizeFirstLetter(el.not_categoria);
            }
          });

          setNewsList(resp.data.result);
          setType("news");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWeather = () => {
    setType("weather");
    setOpenModal(true);
  };

  const handleGrid = () => {
    api
      .get("/gradeprogramacao/all/ativos")
      .then((resp) => {
        if (resp.data.status && resp.data.status === 200) {
          setGrid(resp.data.result);
          console.log(resp.data.result);
          setType("grid");
          setOpenModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeMedia = (id) => {
    let mediaGrid = selectedMedia.filter((el) => {
      if (el.id) {
        return el.id != id;
      } else {
        return el.mul_id != id;
      }
    });
    setSelectedMedia(mediaGrid);
  };

  const saveGrid = (play) => {
    setStartLoader(true);
    let gridMedia = [];

    selectedMedia.forEach((element, ind) => {
      const el = { ...element };
      let time = el.presentation.split(" - ");
      let itemId = "";

      if (el.type === "imagem" || el.type === "video") {
        if (el.con_tipo) {
          itemId = el.midia.mul_id;
        } else {
          itemId = el.mul_id;
        }
      } else if (el.type === "clima") {
        if (el.con_tipo) {
          itemId = el.clima.cli_id;
        } else {
          itemId = el.cli_id;
        }
      } else if (el.type === "noticia") {
        if (el.con_tipo) {
          itemId = el.noticia.not_id;
        } else {
          itemId = el.not_id;
        }
      }
      if (
        el.duration !== "00:00:15" &&
        (el.midiaDaysToSave[0]?.exc_duracao === "00:00:15" ||
          el.midiaDaysToSave[0]?.exc_duracao === null) &&
        el.type === "video"
      ) {
        el.midiaDaysToSave = el.midiaDaysToSave.map((item) => {
          const newItem = { ...item };
          newItem.exc_duracao = el.duration;

          return newItem;
        });
      }

      console.log("SET EL: ", el);

      let media = {
        id: itemId,
        con_tipo: el.type === "imagem" || el.type === "video" ? "midia" : el.type,
        con_duracao: el.duration,
        con_inicio: time[0] === "null" ? null : time[0],
        con_fim: time[1] === "null" ? null : time[1],
        con_ordem: ind + 1,
        exc_exibicaoConteudo: el.midiaDaysToSave,
      };

      gridMedia.push(media);
    });

    if (currentPlayer.pla_lir_id !== "" && currentPlayer.pla_lir_id !== null) {
      let gridContent = {
        lir_id: currentPlayer.pla_lir_id,
        conteudos: gridMedia,
      };

      api
        .put("/listaReproducao", gridContent)
        .then((resp) => {
          if (resp.data.status && resp.data.status === 200) {
            if (play) {
              setStartLoader(false);
              navigate(`/app/playlist/${currentPlayer.pla_lir_id}`, {
                state: { data: currentPlayer, from: currentPath },
              });
            } else {
              setStartLoader(false);
              openSuccessAlert();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setStartLoader(false);
          openErrorAlert(err.response.data.message);
        });
    } else {
      setStartLoader(false);
      openErrorAlert("Player não possui lista de reprodução vinculada.");
    }
  };

  const fetchGrid = (lir_id) => {
    api
      .get(`listaReproducao/${lir_id}`)
      .then((resp) => {
        const { result } = resp.data;
        let grid = result;

        grid.conteudos.forEach((el) => {
          if (el.con_tipo === "midia") {
            el.id = el.con_mul_id;
            el.name = el.midia.mul_nome;
            el.type = el.midia.mul_tipo;
            el.image = el.midia.mul_thumb;
            el.mul_processamento_concluido = el.midia.mul_processamento_concluido;
          } else if (el.con_tipo === "clima") {
            el.id = el.con_cli_id;
            el.name = el.clima.cli_nome;
            el.type = el.con_tipo;
            el.cli_cidade = el.clima.cli_cidade;
            el.cli_uf = el.clima.cli_uf;
          } else if (el.con_tipo === "noticia") {
            el.id = el.con_not_id;
            el.type = el.con_tipo;
            el.category = el.noticia.not_categoria;
          }

          el.duration = el.con_duracao;
          el.midiaDaysToSave = el.exibicao;
          el.presentation = `${el.con_inicio} - ${el.con_fim}`;
        });
        setSelectedMedia(grid.conteudos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backPage = () => {
    const url = `/${window.location.pathname.split("/")[1]}/${window.location.pathname.split("/")[2]
      }`;

    navigate(url);
  };

  const handleOpenMidiaDaysModal = () => {
    setOpenMidiaDaysModal(true);
  };

  const handleCloseMidiaDaysModal = () => {
    setOpenMidiaDaysModal(false);
    setDaysToDisplay(["1", "2", "3", "4", "5", "6", "0"]);
  };

  useEffect(() => {
    if (fadeProps === classes.fadeIn) {
      debounce(() => setFadeProps(""), 500, timeoutRef)();
    }
  }, [onDragEnd]);

  useEffect(() => {
    fetchPlayer();
  }, [id]);

  // console.log("SELECT MEDIA: ", selectedMedia);

  return (
    <Page className={classes.root} title="Editar Player">
      <PageTabTripleTitle
        className={classes.title}
        primary="PLAYER"
        secondary="LISTA DE REPRODUÇÃO"
        tertiary="USUÁRIO"
        active={activeTab}
        changeTab={changeActiveTab}
      />
      {activeTab === "primary" ? (
        <Container className={classes.container}>
          <Box className={classes.fullBox}>
            <Box style={{ display: "flex" }}>
              <TextField
                style={{ display: "flex", flex: 1 }}
                className={classes.field}
                fullWidth
                label="Nome"
                margin="normal"
                name="nome"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.nome}
                variant="outlined"
                required
              />

              <FormControl component="fieldset" style={{ marginTop: 20 }}>
                <FormLabel component="legend">
                  Selecione o tipo de mídia
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="posicao"
                  name="posicao"
                  value={formik.values.posicao}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="paisagem"
                    control={<Radio />}
                    label="Paisagem"
                  />
                  <FormControlLabel
                    value="retrato"
                    control={<Radio />}
                    label="Retrato"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            <TextField
              value={lottery.lot_razaoSocial || ""}
              label="Lotérica"
              disabled
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              fullWidth
              style={{
                marginTop: "16px",
                marginBottom: "8px",
              }}
            />

            <TextField
              className={classes.field}
              fullWidth
              label="Código"
              margin="normal"
              name="codigo"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.codigo}
              variant="outlined"
              required
            />

            <Box style={{ display: "flex" }}>
              <TextField
                style={{ display: "flex", flex: 1 }}
                className={classes.field}
                label="Botão A/1"
                margin="normal"
                name="a1botao"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.a1botao}
                variant="outlined"
              />

              <InputMask
                mask={"99:99:99"}
                value={formik.values.a1duracao}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onBlurCapture={() => handleVerifyBtnTime("a1duracao")}
                disabled={false}
              >
                <TextField
                  style={{ display: "flex", flex: 1 }}
                  label="Duração"
                  margin="normal"
                  name="a1duracao"
                  type="text"
                  variant="outlined"
                />
              </InputMask>
            </Box>

            <Box style={{ display: "flex" }}>
              <TextField
                style={{ display: "flex", flex: 1 }}
                className={classes.field}
                label="Botão A/2"
                margin="normal"
                name="a2botao"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.a2botao}
                variant="outlined"
              />

              <InputMask
                mask={"99:99:99"}
                value={formik.values.a2duracao}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onBlurCapture={() => handleVerifyBtnTime("a2duracao")}
                disabled={false}
              >
                <TextField
                  style={{ display: "flex", flex: 1 }}
                  label="Duração"
                  margin="normal"
                  name="a2duracao"
                  type="text"
                  variant="outlined"
                />
              </InputMask>
            </Box>

            <Box style={{ display: "flex" }}>
              <TextField
                style={{ display: "flex", flex: 1 }}
                className={classes.field}
                label="Botão A/3"
                margin="normal"
                name="a3botao"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="text"
                value={formik.values.a3botao}
                variant="outlined"
              />

              <InputMask
                mask={"99:99:99"}
                value={formik.values.a3duracao}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onBlurCapture={() => handleVerifyBtnTime("a3duracao")}
                disabled={false}
              >
                <TextField
                  style={{ display: "flex", flex: 1 }}
                  label="Duração"
                  margin="normal"
                  name="a3duracao"
                  type="text"
                  variant="outlined"
                />
              </InputMask>
            </Box>
          </Box>

          <Box className={classes.buttonBox}>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleSave}
            >
              Salvar
            </Button>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={backPage}
              style={{
                marginLeft: 5,
                backgroundColor: "#F07C00",
                color: "white",
              }}
            >
              Cancelar
            </Button>
          </Box>
        </Container>
      ) : activeTab === "secondary" ? (
        <>
          <Box className={classes.headerButtons}>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleMediaGrid}
            >
              SELECIONAR MULTIMIDIA
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleUpload}
            >
              UPLOAD DE IMAGEM/VÍDEO
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleUploadNews}
            >
              IMPORTAR NOTÍCIAS
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleWeather}
            >
              IMPORTAR CLIMA
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleGrid}
            >
              IMPORTAR GRADE
            </Button>
          </Box>

          <Container className={classes.mediaCardList}>
            {selectedMedia.map((item, index) => (
              <div
                key={item.con_id || item.mul_id || item.not_id || item.cli_id}
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={onDragOver}
                onDrop={onDrop}
                id={index}
                draggable={true}
              >
                <MediaCard
                  id={item.id}
                  dragAndDrop
                  leftLabel
                  fadeProps={fadeProps}
                  labelNumber={index}
                  className={classes.root}
                  disableEdit
                  index={index}
                  name={item.name}
                  image={item.image}
                  type={item.type}
                  mul_processamento_concluido={item.mul_processamento_concluido}
                  category={item.category || item.midia?.categoria?.cat_nome}
                  duration={
                    item?.midiaDaysToSave?.filter((media) => {
                      if (item.type === "video") {
                        return (
                          media.exc_dia_semana == todayName.dia &&
                          media.exc_duracao !== "00:00:15"
                        );
                      } else {
                        return media.exc_dia_semana == todayName.dia;
                      }
                    })[0]?.exc_duracao || item.duration
                  }
                  presentation={item.midiaDaysToSave}
                  onEdit={() => console.log('Edit')}
                  onDelete={() => removeMedia(item.id ? item.id : item.mul_id)}
                  cidade={item.cli_cidade}
                  uf={item.cli_uf}
                  conId={
                    item.con_id || item.mul_id || item.cli_id || item.not_id
                  }
                />
              </div>
            ))}
          </Container>

          <Box className={classes.buttonBox}>
            <Button
              style={{ marginRight: "20px" }}
              disableElevation
              size="large"
              variant="outlined"
              onClick={() => saveGrid(false)}
              disabled={!currentPlayer.pla_lir_id}
            >
              SALVAR
            </Button>

            <Button
              style={{ marginRight: "20px" }}
              disableElevation
              size="large"
              variant="outlined"
              onClick={() => saveGrid(true)}
            >
              REPRODUZIR
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={() => setConfirmModal(!confirmModal)}
            >
              LIMPAR MÍDIA
            </Button>

            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={backPage}
              style={{
                marginLeft: 20,
                backgroundColor: "#F07C00",
                color: "white",
              }}
            >
              Voltar
            </Button>
          </Box>

          <EditModal
            open={openModal}
            type={type}
            newList={newsList}
            categories={categories}
            selectedMedia={selectedMedia}
            mediaGrid={[]}
            grid={grid}
            setSelectedMedia={setSelectedMedia}
            close={() => {
              setMidiaDaysToSave([]);
              setOpenModal(false);
            }}
            openMidiaDays={handleOpenMidiaDaysModal}
            daysToDisplay={midiaDaysToSave}
            videoDuration={videoDuration}
            setVideoDuration={setVideoDuration}
          />
          <MidiaDaysModal
            open={openMidiaDaysModal}
            close={handleCloseMidiaDaysModal}
            handleDaysToDisplay={handleDaysToDisplay}
            daysToDisplay={daysToDisplay}
            save={setMidiaDaysToSave}
            videoDuration={videoDuration}
            setVideoDuration={setVideoDuration}
          />
        </>
      ) : (
        <Container className={classes.container}>
          <Box className={classes.fullBox}>
            <TextField
              fullWidth
              label="Login"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.email}
              variant="outlined"
              required
            />

            <TextField
              fullWidth
              label="Nova senha"
              margin="normal"
              name="senha"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? "text" : "password"}
              value={formik.values.senha}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      name="showPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              label="Confirme a nova senha"
              margin="normal"
              name="confirmaSenha"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmaSenha}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      name="showConfirmPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className={classes.buttonBox}>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={handleSaveUser}
            >
              Salvar
            </Button>
            <Button
              disableElevation
              size="large"
              variant="outlined"
              onClick={backPage}
              style={{
                marginLeft: 5,
                backgroundColor: "#F07C00",
                color: "white",
              }}
            >
              Cancelar
            </Button>
          </Box>
        </Container>
      )}

      <Loader display={startLoader} />
      <AlertDialog
        open={openAlert}
        handleClose={handleAlertClose}
        type={alertType}
        text={alertText}
        buttonText={alertButtonText}
      />
      <ConfirmActionModal
        open={confirmModal}
        confirm={handleCleanMedia}
        close={() => setConfirmModal(false)}
      />
    </Page>
  );
};

export default EditPlayerView;
